import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import { required, email, emailReg, password, phone, matchingPasswd } from '../../validators/validators'
import { Input, Checkbox } from '../FormsControl/FormsControl'
import s from './LogReg.module.scss'

const LogReg = ({
  handleSubmit, ModeHandler, regMode, failureMsg, displayIcon,
  iconVisibility, pressKeyHandler
}) => {
  return (
    <div className={s.container}>

      <div className={s.contacts}>

        <div className={s.title}>Контакт-центр</div>
        <div className={s.line}></div>
        <div className={s.telephones}>
          15-00 <br />
          099-080-15-00 <br />
          096-080-15-00 <br />
        </div>
        <div className={s.line}></div>
        <div className={s.subTitle}>Графік роботи</div>
        <div className={s.schedule}>

          <div>
            <span className={s.long}>понеділок - четвер<br /></span>
            <span className={s.short}>понед. - четвер<br /></span>
            п'ятниця<br />
            субота<br />
            неділя
          </div>
          <div>
            8:00 - 17:00<br />
            8:00 - 15:45<br />
            вихідний<br />
            вихідний
          </div>
        </div>
        <div className={s.line}></div>
        {/* <img src={logo} alt="logo" /> */}
        <div className={s.email}>
          zvernennya@teploseti.zp.ua <br />
          <a href="http://teploseti.zp.ua">teploseti.zp.ua</a>

        </div>
      </div>

      <form action="" className={s.form} onSubmit={handleSubmit}>

        {regMode
          ? <div className={s.titleForm}>Реєстрація</div>
          : <div className={s.titleForm}>Особистий кабінет</div>
        }

        <Field
          className={s.field}
          name="email"
          // autoFocus={true}
          placeholder=" Електронна пошта"
          component={Input}
          type="email"
          validate={[required, regMode ? emailReg : email]} //==================== Оставить только email =====================
          label="email"
          normalize={pressKeyHandler}
        />

        <Field
          className={s.field}
          name="password"
          placeholder=" Пароль"
          component={Input}
          type={iconVisibility ? "text" : "password"}
          validate={[required, password]}
          label="password"
          displayIcon={displayIcon}
          iconVisibility={iconVisibility}
          normalize={pressKeyHandler}
        />

        {regMode &&
          <Field
            className={s.field}
            name="sPasswd"
            placeholder=" Підтвердження "
            component={Input}
            type={iconVisibility ? "text" : "password"}
            validate={[required, matchingPasswd('password')]}
            label="password"
            displayIcon={displayIcon}
            iconVisibility={iconVisibility}
            normalize={pressKeyHandler}
          />
        }

        {regMode &&
          <div className={s.fieldContainer}>
            <Field
              className={s.field}
              name="phone"
              placeholder=" Телефон 0xxxxxxxxx"
              component={Input}
              type="text"
              validate={[required, phone]}
              label="phone"
              normalize={pressKeyHandler}
            />
          </div>
        }

        {regMode &&
          <div className={s.fieldContainer}>
            <Field
              className={s.field}
              name="agree"
              // placeholder="Телефон"
              component={Checkbox}
              type="checkbox"
              validate={[required]}
            // label="agree"
            />
          </div>
        }


        {regMode
          ? <button type="submit" className={s.buttonForm}>Зареєструвати</button>
          : <button type="submit" className={`${s.buttonForm} ${s.buttonLoginForm}`}>Вхід</button>
        }

        {!regMode
          ? null
          : <div className={s.buttonBack} onClick={ModeHandler}>&#8592; Повернутися</div>
        }

        {!regMode &&
          <div className={s.buttonContainer}>
            <div className={s.link} onClick={ModeHandler}>
              {/* <div className={s.link} > */}
              Реєстрація
            </div>
            {/*<div className={s.link}>
              <NavLink to="/password-reset">Забули пароль?</NavLink>
            </div>*/}
          </div>
        }

      </form>
    </div >
  )
}

const LoginReduxForm = reduxForm({ form: 'login' })(LogReg)

export default LoginReduxForm
