import React from 'react'
// import { useState } from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderContainer from './components/Header/HeaderContainer'
import FooterContainer from './components/Footer/FooterContainer'
import MainPageContainer from './components/MainPage/MainPageContainer'
import LogRegContainer from './components/LogReg/LogRegContainer'
import PasswdResetContainer from './components/PasswdReset/PasswdResetContainer'
import UserActivationContainer from './components/UserActivation/UserActivationContainer'
//import TemporaryPage from './components/TemporaryPage/TemporaryPageContainer'
import "./_variables.scss"
import './App.scss'

// const LogRegContainer = React.lazy(() => import('./components/LogReg/LogRegContainer'))
// const PasswdResetContainer = React.lazy(() => import('./components/PasswdReset/PasswdResetContainer'))
// const UserActivationContainer = React.lazy(() => import('./components/UserActivation/UserActivationContainer'))

const App = () => {
  const userEmail = useSelector((state) => state.userPage.userEmail)


  //====================== Раскомментировать для закрытия доступа к кабинету ====================
 // const [secret, setSecret] = useState('')
  //const secretHandler = (text) => {
   //  setSecret(text)
   //}

   //if (secret !== '123qwe') return (
    // <div className={userEmail ? 'foneWhite' : 'foneBlue'} >
     // <div className="container">
     //    <HeaderContainer />
      //   <TemporaryPage secretHandler={secretHandler} secret={secret} />
      //   <FooterContainer />
     //  </div >
    // </div >
  // )
  //==================  Раскомментировать для закрытия доступа к кабинету / =====================



  return (
    <div className={userEmail ? 'foneWhite' : 'foneBlue'} >
      <div className="container">
        <div>
          <HeaderContainer />
        </div>

        <Route exact path="/" component={MainPageContainer} />
        {/* <React.Suspense fallback={<div>Loading ... </div>}> */}
        <Route exact path="/login" component={LogRegContainer} />
        {/*<Route exact path="/password-reset" component={PasswdResetContainer} />*/}
        <Route exact path="/activation-successful" component={UserActivationContainer} />
        {/* </React.Suspense> */}

        <FooterContainer />
      </div >
    </div >
  )
}

export default App
